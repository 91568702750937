import React, { useContext, useEffect, useState } from "react";
import { CourseContext } from "../../Context/MediatestContext";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../images/Spinner@1.25x-0.5s-200px-200px.svg";

export default function EnglishPrograms() {
  const { courseData, fetchCourseData } = useContext(CourseContext);
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const navigate = useNavigate();

  const setLanguage = (language) => {
    setCurrentLanguage(language);
    localStorage.setItem("language", language);
  };

  const getDirection = () => {
    return currentLanguage === "en" ? "ltr" : "rtl";
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setCurrentLanguage(storedLanguage);
    }
  }, []);

  const id = useParams();

  useEffect(() => {
    if (id.spitem) {
      fetchCourseData(id.spitem);
    }
  }, [id.spitem]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // Set loading to false after 1 second
    }, 2000);

    return () => clearTimeout(timer); // Clear timer on component unmount
  }, []);

  const handleRedirect = (courseId) => {
    navigate(`/course/${courseId}`);
  };

  const handleRedirecttoconactus = () => {
    navigate(`/contactus`);
  };

  return (
    <>
      {isLoading ? ( // Show loading screen if isLoading is true
        <div className="loading-spinner-container">
          <div className="loading-spinner">
            <img src={LoadingSpinner} alt="Loading" />
          </div>
        </div>) : (
        <>
          {/* <div className={`distanceCoveritem d-flex flex-column justify-content-center align-items-center p-5`} style={{ direction: getDirection() }}> */}
          <div className={`distanceCoveritem d-flex flex-column justify-content-center align-items-center  `} style={{ direction: getDirection() }}>

            {/* <h6 className="text-blackk-50 text-center px-5" style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}>
              {currentLanguage === "en" ? "Home / English Programs" : "الرئيسية / البرامج الإنجليزية"}
            </h6> */}


            <h1
              style={{
                fontFamily: currentLanguage === "ar" ? "cairo" : "Poppins",
              }}
              className="text-white text-center px-3"
            >
              {currentLanguage === "en" ? "English Programmes" : "البرامج الإنجليزية"}
            </h1>
          </div>
          <div className="mb-5"></div>
          <div className="container text-center   my-5 p-5">
           

            {/* <h4 className="landing-paragraph px-5 my- mt-5 text " style={{ fontSize: '30px', fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}>
              {currentLanguage === "en" ? "English Programs" : "البرامج الإنجليزية"}
            </h4> */}
            {/* <div className="bottom-outline-2 my-2"></div> */}
            <p style={{ fontSize: '20px', lineHeight: "2.5", color: "#042956", fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }} className="my-5 landing-paragraph text">
              {currentLanguage === "en" ? (
                <>
                  Knowledge Hub is pleased to provide innovative English Learning programmes, combining language skills with professional expertise in areas such as AI, Law, Business, and Healthcare. Our courses, from summer programmes for young learners to exam preparation courses, are crafted to enhance English fluency and furnish you with the required industry-specific communication skills. Ideal for professionals, students, and inquisitive young minds, our programmes are designed to support your academic, professional, and global aspirations. Begin your journey with Knowledge Hub to discover new opportunities and achieve your goals.
                </>
              ) : (
                <>
                  تقدم شركة مكامن المعرفة برامج مبتكرة لتعلم اللغة الإنجليزية، تجمع بين المهارات اللغوية والخبرة المهنية في مجالات عديدة مثل الذكاء الاصطناعي والقانون والأعمال والرعاية الصحية. تم تصميم برامجنا، من البرامج الصيفية للمتعلمين الصغار إلى دورات التحضير للامتحانات، لتعزيز طلاقة اللغة الإنجليزية وتزويد المتدربين بمهارات الاتصال المطلوبة الخاصة بالصناعة والاعمال والتقنية        </>
              )}
            </p>
            
            <div className="row text-center" style={{ direction: getDirection() }}>
              {courseData.map((program, index) => (
                <div className="col-md-4 my-3" key={index}>
                  <div className="captionParent">
                    <img src={program.image} alt="" className="img-fluid rounded-2" style={{ maxHeight: '265px', minWidth: '470px' }} />
                    <div className="caption" style={{ padding: '0px', display: 'flex', flexDirection: 'column', alignItems: 'center', fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}>
                      {/* <p className="text-white mt-3" style={{ fontWeight: 'bold', fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}>{currentLanguage === "en" ? program.title_en.replace(/font-(size|family)/g, '') : program.title_ar.replace(/font-(size|family)/g, '')}</p> */}
                      <p className="text-white" dangerouslySetInnerHTML={{ __html: currentLanguage === "en" ? program.hover_en.replace(/font-(size|family)/g, '') : program.hover_ar.replace(/font-(size|family)/g, '') }}></p>
                      <div className="button" style={{ position: "absolute", bottom: 0, left: 0, right: 0, display: "flex", justifyContent: "center" }}>

                        <button
                          className="submit-btn border-0 text-white   my-2 p-3 text-center h-20 d-flex justify-content-center align-items-center pt-3 rounded-2"
                          onClick={() => handleRedirecttoconactus()}
                          style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}  >
                          {currentLanguage === "en" ? "Contact Us" : "تواصل معنا"}
                        </button>
                      </div>
                    </div>

                  </div>
                  <h5 style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }} className="sectionTitle my-2 py-1 px-1 mb-sm-3 mb-xl-3 mb-xxl-3 text-center h-25 d-flex justify-content-center align-items-center pt-3 rounded-bottom-2">
                    {currentLanguage === "en" ? program.title_en : program.title_ar}
                  </h5>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}
