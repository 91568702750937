import React, { useState, useContext, useEffect } from "react";
import { CourseInfoContext } from "../../../Context/MediatestContext";
import { useNavigate, useParams } from "react-router-dom"; // Import useNavigate instead of useHistory
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { ProjectsContext } from "../../../Context/MediatestContext"; // Update the path

import { EffectCoverflow, Pagination } from 'swiper/modules';


export default function CourseContent() {
  const { courseInfoData, fetchCourseInfoData } = useContext(CourseInfoContext);
  const id = useParams();
  const storedLanguage = localStorage.getItem("language") || "en"; // Retrieve language preference from local storage


  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const [currentLanguage, setCurrentLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem("language");
    return storedLanguage || "en"; // Default language is English
  });

  // useEffect(() => {
  //   if (id) {
  //     fetchCourseInfoData(id.coursecontent);
  //   }
  // }, [id.coursecontent, fetchCourseInfoData]);
  useEffect(() => {
    if (id.coursecontent) {
      fetchCourseInfoData(id.coursecontent);
    }
  }, [id.coursecontent]);

  const handleRedirect = (courseId) => {
    navigate(`/course/${courseId}`); // Use navigate instead of history.push
  };

  if (!courseInfoData) {
    return <div>Loading...</div>;
  }
  const getDirection = () => {
    // Determine text direction based on language preference
    return storedLanguage === "ar" ? "rtl" : "ltr";
  };

  return (
    <>
      {/* <section className="course-content"> */}
      <div
        className={`distanceCoveritem d-flex flex-column justify-content-center align-items-center p-5`}
      >

      </div>
      <p style={{ direction: getDirection(), marginLeft: storedLanguage === "en" ? '80px' : 'auto', marginRight: storedLanguage === "ar" ? '20px' : 'auto', textAlign: storedLanguage === "ar" ? 'justify' : 'start', fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}>
        {storedLanguage === "en" ? "specialty" : "تخصص"} /
        {storedLanguage === "en" ? courseInfoData.category_title_en : courseInfoData.category_title_ar} /
        {storedLanguage === "en" ? courseInfoData.title_en : courseInfoData.title_ar}
      </p>

      <div className="navCourseBckColor mb-4 pb-3"></div>
      <div className="container mt-3">
        <div className="row">
          <h6 className="mt-2 mb-5 text-black-50">
            {/* Your existing code */}
          </h6>
          <div className="d-flex align-items-center position-relative my-5">
            {storedLanguage === 'ar' ? (
              <>
                <div className="col-md-5 offset-md-1 courseImgafter">
                  <img src={courseInfoData && courseInfoData.course_info ? courseInfoData.course_info.image : ''} className="img-fluid py-3" alt="" />
                </div>
                <div className="col-md-6" style={{ display: "table-cell" }}>
                  <h2
                    className="lh-5 section-title w-75 fw-normal"
                    style={{ direction: getDirection(), display: "table-cell", fontFamily: storedLanguage === "ar" ? 'cairo' : 'Poppins', fontSize: "35px" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        storedLanguage === "en"
                          ? courseInfoData.title_en
                          : courseInfoData.title_ar
                    }}
                  ></h2>
                  {/* <p style={{ fontFamily: storedLanguage === "ar" ? 'cairo'  : 'Poppins' }}
            dangerouslySetInnerHTML={{
              __html:
                storedLanguage === "en"
                  ? courseInfoData.hover_en
                  : courseInfoData.hover_ar
            }}
            ></p> */}
                  {/* </div> */}
                </div>
              </>
            ) : (
              <>
                <div className="col-md-6">
                  <div className="text" style={{ display: "table-cell" }}>
                    <h2
                      className="lh-10 section-title  "
                      style={{ direction: getDirection(), fontFamily: storedLanguage === "ar" ? 'cairo' : 'Poppins', fontSize: "35px", display: "table-cell" }}
                      dangerouslySetInnerHTML={{
                        __html:
                          storedLanguage === "en"
                            ? courseInfoData.title_en
                            : courseInfoData.title_ar
                      }}
                    ></h2>
                    {/* <p style={{ fontFamily: storedLanguage === "ar" ? 'cairo'  : 'Poppins' }}
            dangerouslySetInnerHTML={{
              __html:
                storedLanguage === "en"
                  ? courseInfoData.hover_en
                  : courseInfoData.hover_ar
            }}
          ></p> */}
                  </div>
                </div>
                <div className="col-md-5 offset-md-1 courseImg">
                  <img src={courseInfoData && courseInfoData.course_info ? courseInfoData.course_info.image : ''} className="img-fluid py-3" alt="" />
                </div>
              </>
            )}
          </div>

          <div className="landing-paragraph lh-5 my-5">
            <h3 style={{ fontFamily: storedLanguage === "ar" ? 'cairo' : 'Poppins', direction: storedLanguage === "ar" ? "rtl" : "ltr", textAlign: storedLanguage === "ar" ? "justify" : "" }}>{storedLanguage === "en" ? "Aims of the course" : "أهداف الدورة"}</h3>
            {courseInfoData.course_info && (
              <p dangerouslySetInnerHTML={{ __html: storedLanguage === "en" ? courseInfoData.course_info.Content_en_1 : courseInfoData.course_info.Content_ar_1 }} style={{ direction: storedLanguage === "ar" ? "rtl" : "ltr", textAlign: storedLanguage === "ar" ? "justify" : "" }}></p>
            )}
          </div>
          <div className="landing-paragraph lh-5 my-5">
            <h2 style={{ fontFamily: storedLanguage === "ar" ? 'cairo' : 'Poppins', direction: storedLanguage === "ar" ? "rtl" : "ltr", textAlign: storedLanguage === "ar" ? "justify" : "" }}>{storedLanguage === "en" ? "Who should take this course?" : "من ينبغي أن يأخذ هذا الدورة؟"}</h2>
            {courseInfoData.course_info && (
              <div className="lh-5 my-3 landing-paragraph">
                <p dangerouslySetInnerHTML={{ __html: storedLanguage === "en" ? courseInfoData.course_info.Content_en_2 : courseInfoData.course_info.Content_ar_2 }} style={{ direction: storedLanguage === "ar" ? "rtl" : "ltr", textAlign: storedLanguage === "ar" ? "justify" : "" }}></p>
              </div>
            )}
          </div>
          <div className="lh-5 my-5">
            <h3 className="landing-paragraph" style={{ direction: storedLanguage === "ar" ? "rtl" : "ltr", textAlign: storedLanguage === "ar" ? "justify" : "" }}>{storedLanguage === "en" ? "Course outline" : "مخطط الدورة"}</h3>
            {/* Add conditional check */}
            {courseInfoData.course_info && (
              <p dangerouslySetInnerHTML={{ __html: storedLanguage === "en" ? courseInfoData.course_info.Content_en_3 : courseInfoData.course_info.Content_ar_3 }} style={{ fontFamily: storedLanguage === "ar" ? 'cairo' : 'Poppins', direction: storedLanguage === "ar" ? "rtl" : "ltr", textAlign: storedLanguage === "ar" ? "justify" : "" }}></p>
            )}
          </div>
        </div>

        <div className="container mt-3">


          <div className="container">
            <h1 style={{ fontFamily: storedLanguage === "ar" ? 'cairo' : 'Poppins' }} className="landing-paragraph text-center">
              {storedLanguage === "ar" ? "الكورسات ذات صلة" : "Related Courses"}
            </h1>
            <div className="bottom-outline"></div>
            <div className="row">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 50,
                  modifier: 1,
                  slideShadows: false, // Disable slide shadows
                }}
                pagination={true}
                initialSlide={2} // Set the initial slide index to 1 (second slide)
                modules={[EffectCoverflow, Pagination]}
                className="mySwiper"
              >
                {/* Filter and map over each project with details */}
                {courseInfoData.related_corses && courseInfoData.related_corses.courses
                  .filter(course => course.details && course.details.length > 0) // Filter courses with details
                  .map((course, index) => (
                    <SwiperSlide key={index} style={{ margin: "0 25px" }}>
                      {/* Apply onClick to the card */}
                      <div className="card" style={{ maxWidth: '303.16px', maxHeight: '320.02px' }} >
                        <img
                          src={`https://knowhub.sa/knowhup/public/course_images/${course.image}`} // Constructing the complete image URL
                          alt={storedLanguage === "ar" ? course.title_ar : course.title_en} // Assuming you have title in your course data
                          className="img-fluid rounded-2  landing-bg"
                        />


                        <div className="card-body">
                          <a onClick={() => handleRedirect(course.id)} href="#" className="card-title">{storedLanguage === "ar" ? course.title_ar : course.title_en}</a>

                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>

        </div>

      </div >
      {/* </section> */}
    </>
  );

}
