import landingOne from "../../images/35523547187e38ce6c250e600cf0d630.png";
import HomeStyle from "../Home/Home.module.css";
import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CourseContext } from "../../Context/MediatestContext";
import { useParams } from "react-router-dom";

const Landing = ({ courseData, fetchCourseData }) => {
  const [currentLanguage, setCurrentLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem("language");
    return storedLanguage || "en"; // Default language is English
  });

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setCurrentLanguage(storedLanguage);
    }
  }, []);

  const setLanguage = (language) => {
    setCurrentLanguage(language);
    localStorage.setItem("language", language);
  };

  const getDirection = () => {
    return currentLanguage === "ar" ? "rtl" : "ltr";
  };

  const firstCourseData = courseData && courseData.length > 0 ? courseData[0] : null;

  return (
    <div className="landing">
      <div className="container">
        <div className="row">
          {currentLanguage === "ar" ? (
            <>
              <div className="col-md-5">
                <div className="image">
                  {firstCourseData && (
                    <img src={firstCourseData.image} alt="" className="img-fluid rounded-2" />
                  )}

                </div>
              </div>
              <div className="col-md-7 "> {/* Add d-flex justify-content-center */}

                <div className="text" style={{ direction: getDirection(), textAlign: "start", color: "#042956",padding:"65px 35px" }}>

                  {firstCourseData && (
                    <h1 className="text-white" style={{ direction: getDirection(), textAlign: "justify", fontFamily: currentLanguage === "ar" ? "cairo" : "inherit" }}>
                      {firstCourseData.title_ar}
                    </h1>
                  )}
                  {firstCourseData && (
                    <p
                      className="text-white-50"
                      style={{
                        direction: getDirection(),
                        textAlign: "justify",
                        fontFamily: currentLanguage === "ar" ? "cairo" : "Poppins",
                      }}
                      dangerouslySetInnerHTML={{ __html: firstCourseData.Content_ar.replace(/font-(size|family)/g, '') }}
                    ></p>
                  )}

{/* <Link to="/specialty/2">
  <button id="trynowbtn" className={`trynowbtn btn text-white rounded-pill mt-3 ${HomeStyle.customButton}`} style={{ direction: getDirection(), textAlign: "start", fontFamily: currentLanguage === "ar" ? "cairo" : "inherit" ,width:"125px"}}>
    {"ابدأ هنا"}
    <span style={{ width: "10px", height: "10px", display: "inline-block" }}>&#8592;&nbsp;</span>
  </button>
</Link> */}
<Link to="/specialty/2">
  <button id="trynowbtn" className={`trynowbtn btn text-white rounded-pill mt-3 ${HomeStyle.customButton}`} style={{ direction: getDirection(), textAlign: "start", fontFamily: currentLanguage === "ar" ? "cairo" : "inherit" ,width:"125px"}}>
    {"ابدأ هنا"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#8592;
  </button>
</Link>


                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-md-7">
              <div className="text" style={{ direction: getDirection(), textAlign: "start", color: "#042956",padding:"65px 35px" }}>

                   {/* <h1 className="text-white">Welcome to</h1> */}
                  {firstCourseData && <h1 className="text-white">{firstCourseData.title_en}</h1>}
                  {firstCourseData && (
                    <p className="text-white-50" dangerouslySetInnerHTML={{ __html: firstCourseData.Content_en }}></p>
                  )}



                  
                
<Link to="/specialty/2">
  <button id="trynowbtn" className={`trynowbtn btn text-white rounded-pill mt-3 d-md-block   ${HomeStyle.customButton}`} style={{ direction: getDirection(), textAlign: "start", width: "130px", fontFamily: currentLanguage === "ar" ? "cairo" : "inherit",width:"135px" }}>
    {currentLanguage === "ar" && <span style={{ width: "50px", height: "30px", display: "inline-block"}}>&#8592;&nbsp;</span>}
    {currentLanguage === "ar" ? "ابدأ هنا" : "Begin Here"}
    {currentLanguage !== "ar" && <span style={{ width: "10px", height: "10px", display: "inline-block"}}>&nbsp;&nbsp;&#8594;</span>}
  </button>
</Link>

                </div>
              </div>
              <div className="col-md-5">
                <div className="image">
                  {firstCourseData && (
                    <img src={firstCourseData.image} alt="" className="img-fluid rounded-2" />
                  )}

                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Landing;
