import React, { useContext } from "react";
import BlogStyle from "./Blog.module.css";
import { Link } from "react-router-dom";
import { PlogsContext } from "../../Context/MediatestContext"; // Import PlogsContext

export default function Blog() {
  const plogsData = useContext(PlogsContext); // Consume PlogsContext
  const language = localStorage.getItem("language") || "en"; // Retrieve language preference from local storage

  const getDirection = () => {
    // Determine text direction based on language
    return language === "ar" ? "rtl" : "ltr";
  };

  return (
    <>
      <div
        className={`distanceCoveritem d-flex flex-column justify-content-center align-items-center `}
      >
        {/* <div className={`d-flex flex-column justify-content-center align-items-center  min-vh2`} dir={getDirection()}> */}
        <h1 style={{ fontFamily: language === "ar" ? 'cairo' : 'Poppins' }} className="text-white text-center px-5">{language === "en" ? "Blogs" : "المدونات"}</h1>
        {/* </div> */}
      </div>
      {/* <div className={`${BlogStyle.blogCover} `}></div> */}

      <section id="blog" className="py-5">
        <div className="container my-5">
          <div className="row" style={{ fontFamily: language === "ar" ? 'cairo' : 'Poppins' }}>
            {/* Map through plogsData and render blog items */}
            {plogsData.map((blog, index) => {
              // Extracting content and selecting first three sentences
              const content = language === "en" ? blog.Content_en : blog.Content_ar;
              const sentences = content.split(/[.!?]/);
              const firstThreeSentences = sentences.slice(0, 3).join('. ') + '.';
              return (
                <div className="col-md-6   col-lg-6  col-12 col-sm-12 " key={index}>
                  <Link to={`/blog/${blog.id}`}>
                    <img
                      src={blog.image}
                      alt=""
                      className="img-fluid rounded-2"
                      style={{ minWidth: '-webkit-fill-available', height: '290px' }} // Set width and height inline
                    />


                    <div className="blogsContent" style={{ 'direction': getDirection() }} >
                      <h3 className="mt-3">{language === "en" ? blog.title_en : blog.title_ar}</h3>
                      <p>{blog.created_at.slice(0, blog.created_at.indexOf('.'))}</p> {/* Extracting datetime */}

                      {/* <p dangerouslySetInnerHTML={{ __html: firstThreeSentences }}></p> */}
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div >
      </section >
    </>
  );
}
