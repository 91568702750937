import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const CourseContext = createContext();
export const SpecialtyContext = createContext();
export const PlogsContext = createContext();
export const plogContext = createContext();
export const ContactContext = createContext();
export const CategoryContext = createContext();
export const ProjectsContext = createContext();
export const ProfileContext = createContext(); // Add ProfileContext
export const CourseInfoContext = createContext();


export const fetchSpecialtyData = async () => {
  try {
    const response = await axios.get("https://knowhub.sa/knowhup/public/api/get-navbar");
    return response.data.data;
  } catch (error) {
    console.error("Error fetching specialty data:", error);
    return [];
  }
};

export const fetchProjectsData = async () => {
  try {
    const response = await axios.get("https://knowhub.sa/knowhup/public/api/Projects");
    return response.data.data;
  } catch (error) {
    console.error("Error fetching projects data:", error);
    return [];
  }
};
export const fetchCourseInfoData = async (id) => {
  try {
    const response = await axios.get(`https://knowhub.sa/knowhup/public/api/courseinfo/${id}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching course info data:", error);
    return [];
  }
};

export const fetchPlogsData = async () => {
  try {
    const response = await axios.get("https://knowhub.sa/knowhup/public/api/plogs");
    return response.data.data;
  } catch (error) {
    console.error("Error fetching plogs data:", error);
    return [];
  }
};

export const fetchProfileData = async () => {
  try {
    const response = await axios.get("https://knowhub.sa/knowhup/public/api/profile");
    return response.data.data;
  } catch (error) {
    console.error("Error fetching profile data:", error);
    return [];
  }
};

const MediatestContextProvider = (props) => {
  const [courseData, setCourseData] = useState([]);
  const [specialtyData, setSpecialtyData] = useState([]);
  const [plogsData, setPlogsData] = useState([]);
  const [plogData, setplogData] = useState([]);
  const [contactResponse, setContactResponse] = useState(null);
  const [CategoryData, setCategoryData] = useState([]);
  const [projectsData, setProjectsData] = useState([]);
  const [profileData, setProfileData] = useState([]); // Declare profileData state

  const [courseInfoData, setCourseInfoData] = useState([]);


  const fetchCourseData = async (id) => {
    try {
      const response = await axios.get(`https://knowhub.sa/knowhup/public/api/get-navbar-details/${id}`);
      setCourseData(response.data.data);
    } catch (error) {
      console.error("Error fetching course data:", error.message);
    }
  };

  const fetchplogData = async (id) => {
    try {
      const response = await axios.get(`https://knowhub.sa/knowhup/public/api/plogs/${id}`);
      setplogData(response.data.data);
    } catch (error) {
      console.error("Error fetching plog data:", error.message);
    }
  };
  const fetchCourseInfoData = async (id) => {
    try {
      const response = await axios.get(`https://knowhub.sa/knowhup/public/api/courseinfo/${id}`);
      setCourseInfoData(response.data.data);
    } catch (error) {
      console.error("Error fetching course info data:", error.message);
    }
  };
  const fetchCategoryData = async (id) => {
    try {
      const response = await axios.get(`https://knowhub.sa/knowhup/public/api/course/${id}`);
      setCategoryData(response.data.data);
    } catch (error) {
      console.error("Error fetching course data:", error.message);
    }
  };

  const postContactInfo = async (formData) => {
    try {
      const response = await axios.post("https://knowhub.sa/knowhup/public/api/contactus", formData);
      setContactResponse(response.data);
    } catch (error) {
      console.error("Error posting contact information:", error.message);
    }
  };

  useEffect(() => {
    fetchSpecialtyData().then(data => setSpecialtyData(data));
    fetchPlogsData().then(data => setPlogsData(data));
    fetchCategoryData().then(data => setCategoryData(data));
    fetchProjectsData().then(data => setProjectsData(data));
    fetchProfileData().then(data => setProfileData(data)); // Fetch profile data
    fetchCourseInfoData().then(data => setCourseInfoData(data)); // Add this line

  }, []);

  return (
    <CourseContext.Provider value={{ courseData, fetchCourseData }}>
      <SpecialtyContext.Provider value={specialtyData}>
        <PlogsContext.Provider value={plogsData}>
          <plogContext.Provider value={{ plogData, fetchplogData }}>
            <ContactContext.Provider value={{ contactResponse, postContactInfo }}>
              <CategoryContext.Provider value={{ CategoryData, fetchCategoryData }}>
                <ProjectsContext.Provider value={{ projectsData, fetchProjectsData }}>
                  <ProfileContext.Provider value={profileData}>


                    <CourseInfoContext.Provider value={{ courseInfoData, fetchCourseInfoData }}>
                      {props.children}
                    </CourseInfoContext.Provider>

                  </ProfileContext.Provider>
                </ProjectsContext.Provider>
              </CategoryContext.Provider>
            </ContactContext.Provider>
          </plogContext.Provider>
        </PlogsContext.Provider>
      </SpecialtyContext.Provider>
    </CourseContext.Provider>
  );
};

export default MediatestContextProvider;
