import landingTwo from "../../images/SVG/vision.svg";
import React, { useContext, useState, useEffect } from "react";

const AboutUs = ({ courseData, fetchCourseData }) => {
  const [currentLanguage, setCurrentLanguage] = useState("en"); // Default language is English

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setCurrentLanguage(storedLanguage);
    }
  }, []);

  const setLanguage = (language) => {
    setCurrentLanguage(language);
    localStorage.setItem("language", language);
  };

  const getDirection = () => {
    return currentLanguage === "ar" ? "rtl" : "ltr";
  };

  const getSloganClass = () => {
    return currentLanguage === "ar" ? "slogan-arabic" : "slogan-english";
  };

  // Get the courseData of index 1
  const secCourseData = courseData && courseData.length > 1 ? courseData[1] : null;
  const thirdCourseData = courseData && courseData.length > 2 ? courseData[2] : null;

  const getTextAlignmentClass = () => {
    return currentLanguage === "ar" ? "aboutus" : "aboutusen";
  };
  const titleClass = currentLanguage === "ar" ? "title-ar" : "title-en";
  const contentClass = currentLanguage === "ar" ? "content-ar" : "content-en";


  return (
    <>
      <section className={`my-5 ${getTextAlignmentClass()}`} style={{ direction: getDirection() }}>
        <div className="container">
          <div className="row">

            <div className={`col-md-4 min-vh ${getSloganClass()} ${getTextAlignmentClass()}`} style={{ direction: getDirection(), textAlign: currentLanguage === "ar" ? 'justify' : 'start' }}>

              {/* </h1> */}


            </div>
            <div className="col-1"></div>

            <div className={` col-md-7 ${getTextAlignmentClass()} mt-5`} style={{ direction: getDirection() }}>
              <h1 className="section-title "  style={{ direction: getDirection(), textAlign: currentLanguage === "ar" ? 'justify' : 'start', fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}>
                {secCourseData ? (currentLanguage === "ar" ? secCourseData.title_ar : secCourseData.title_en) : "Loading..."}
              </h1>



              <p className={`aboutus-content ${contentClass} mt-4 lh-lg`}
                style={{
                  direction: getDirection(),
                  textAlign: currentLanguage === "ar" ? 'justify' : 'start',
                  fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins',
                  color: '#042956'
                }}>
                {secCourseData ?
                  <p dangerouslySetInnerHTML={{
                    __html: currentLanguage === "en" ?
                      secCourseData.Content_en :
                      secCourseData.Content_ar.replace(/font-family/g, '')
                  }} />

                  : "Loading..."
                }
              </p>



            </div>

          </div>


        </div>

        <div className="container my-5">
          <div className="row my-5">
            {currentLanguage === "ar" ? (
              <>


                <div className="col-md-5  col-lg-6  my-5" data-aos="fade-down">
                  <h1 className="section-title" style={{ direction: getDirection(), textAlign: 'justify', fontFamily: 'cairo' }}>
                    {thirdCourseData ?
                      thirdCourseData.title_ar
                      : "Loading..." // or some placeholder text
                    }
                  </h1>
                  <p className="landing-paragraph lh-lg" style={{ direction: getDirection(), textAlign: 'justify', fontFamily: 'cairo' }}>
                    {thirdCourseData ?
                      <p dangerouslySetInnerHTML={{ __html: thirdCourseData.Content_ar.replace(/font-family/g, '') }}></p>
                      : "Loading..." // or some placeholder text
                    }
                  </p>

                </div>
                <div className="col-md-7 col-lg-6 my-5" data-aos="fade-down">
                  {thirdCourseData ?
                    <img src={thirdCourseData.image} alt="" className="img-fluid rounded-2 mt-2" />
                    : <img src={landingTwo} alt="" className="img-fluid rounded-2" />
                  }
                </div>


              </>
            ) : (
              <>

                <div className="col-md-5  col-lg-6  my-5" data-aos="fade-down">
                  <h1 className="section-title" style={{ direction: getDirection(), textAlign: 'start' }}>
                    {thirdCourseData ?
                      thirdCourseData.title_en
                      : "Loading..." // or some placeholder text
                    }
                  </h1>
                  <p className="landing-paragraph lh-lg" style={{ direction: getDirection(), textAlign: 'start' }}>
                    {thirdCourseData ?
                      <p dangerouslySetInnerHTML={{ __html: thirdCourseData.Content_en }}></p>
                      : "Loading..." // or some placeholder text
                    }
                  </p>

                </div>

                <div className="col-md-7 col-lg-6 my-5" data-aos="fade-down">
                  {thirdCourseData ?
                    <img src={thirdCourseData.image} alt="" className="img-fluid rounded-2 mt-2" style={{ marginBottom: "5px" }} />
                    : <img src={landingTwo} alt="" className="img-fluid rounded-2" style={{ marginBottom: "5px" }} />
                  }
                </div>


              </>
            )}
          </div>
        </div>

      </section >
    </>
  );
};

export default AboutUs;
