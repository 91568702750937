import React, { useState, useEffect } from "react";
import SpecialtyStyle from "../Specialty/Speciality.module.css";
import { fetchProfileData } from "../../Context/MediatestContext"; // Import fetchProfileData

export default function CompanyProfile() {
  const [profileData, setProfileData] = useState(null);
  const storedLanguage = localStorage.getItem("language") || "en"; // Retrieve language preference from local storage

  useEffect(() => {
    fetchProfileData().then((data) => setProfileData(data));
  }, []);

  return (
    <>
      <div className={`specialtyCover d-flex flex-column justify-content-center align-items-center `}>
        <h1
          style={{
            fontFamily: storedLanguage === "ar" ? "cairo" : "Poppins",
          }}
          className="text-white text-center px-3"
        >
          {storedLanguage === "en" ? "Company Profile" : " ملف الشركة"}
        </h1>
      </div>
      <section className={`mt-5`}>
        <div className="container d-flex justify-content-center align-items-center " style={{ height: "90vh" }}>
          {profileData && ( // Check if profileData is available
            <iframe 
              src={`https://docs.google.com/viewer?url=${encodeURIComponent(profileData)}&embedded=true`}  
              title="PDF Viewer" 
              width="90%" // Set width to 80% of the container
              height="500px" // Set height to 80% of the viewport height
              style={{ border: "none" }} // Optional: remove border for a cleaner look
            ></iframe>
          )}
        </div>
      </section>
    </>
  );
}
