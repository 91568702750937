import React, { useContext, useState, useEffect } from "react";
import whatsapp from "../../images/SVG/WAicon.svg";
import { ContactContext } from "../../../src/Context/MediatestContext";

export default function ContactForm() {
  const { postContactInfo } = useContext(ContactContext);
  const [currentLanguage, setCurrentLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem("language");
    return storedLanguage || "en"; // Default language is English
  });

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setCurrentLanguage(storedLanguage);
    }
  }, []);

  const setLanguage = (language) => {
    setCurrentLanguage(language);
    localStorage.setItem("language", language);
  };

  const getDirection = () => {
    return currentLanguage === "ar" ? "rtl" : "ltr";
  };
  const getBlock = () => {
    return currentLanguage === "ar" ? "block" : "";
  };

  // Define a function to determine label alignment based on the current language
  const getLabelAlignment = () => {
    return currentLanguage === "ar" ? "justify" : "justify";
  };

  function submitMessageForm(e) {
    e.preventDefault();
    const formData = {
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      email: e.target.email.value,
      message: e.target.submitMessage.value
    };
    postContactInfo(formData);
    alert(
      "Thank you for your message. We will get back to you as soon as possible."
    );
    e.target.reset();
    return false;
  }

  return (
    <div className="container">
      <form onSubmit={submitMessageForm}>
        <div className="text-center landing-paragraph p-5">
          <h1 className="">
            {currentLanguage === "ar" ? "اتصل بنا" : "Contact Us"}
          </h1>
          <p className="mx-5">
            {currentLanguage === "ar"
              ? "هل لديك مشروع في الاعتبار تعتقد أننا سنكون ملائمين له؟ نحن نحب أن نعرف ما تفكر فيه"
              : "Have a project in mind that you think we'd be a great fit for? We'd love to know what you're thinking!"}
          </p>
        </div>
        <div className="row" style={{
          direction: getDirection(),
          // Assuming you want to set display to "block"
          // Assuming you want to set display to "block"
        }}>
          <div className="col-md-4">
            <div className="mb-3">
              <label
                htmlFor="first_name"
                className="form-label landing-paragraph"
                style={{
                  textAlign: getLabelAlignment(),
                  direction: getDirection(),
                  display: getBlock() // Assuming you want to set display to "block"
                }}
              >
                {currentLanguage === "ar" ? "الاسم الأول" : "First Name"}
              </label>
              <input
                required // This ensures that the input field is required
                type="text"
                className="form-control my-input"
                id="first_name"
                placeholder={
                  currentLanguage === "ar"
                    ? "أدخل الاسم الأول هنا"
                    : "Input your first name here"
                }
                style={{
                  textAlign: getLabelAlignment(),
                  direction: getDirection(),
                  display: getBlock() // Assuming you want to set display to "block"
                }}
              />

            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3">
              <label
                htmlFor="last_name"
                className="form-label landing-paragraph"
                style={{
                  textAlign: getLabelAlignment(),
                  direction: getDirection(),
                  display: getBlock() // Assuming you want to set display to "block"
                  // Assuming you want to set display to "block"
                }}              >
                {currentLanguage === "ar" ? "اسم العائلة" : "Last Name"}
              </label>
              <input
                required
                type="text"
                className="form-control my-input"
                id="last_name"
                placeholder={
                  currentLanguage === "ar"
                    ? "أدخل اسم العائلة هنا"
                    : "Input your last name here"
                }
                style={{
                  textAlign: getLabelAlignment(),
                  direction: getDirection(),
                  display: getBlock() // Assuming you want to set display to "block"
                  // Assuming you want to set display to "block"
                }}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3">
              <label
                htmlFor="email"
                className="form-label landing-paragraph"
                style={{
                  textAlign: getLabelAlignment(),
                  direction: getDirection(),
                  display: getBlock() // Assuming you want to set display to "block"
                  // Assuming you want to set display to "block"
                }}              >
                {currentLanguage === "ar" ? "البريد الإلكتروني" : "Email Address"}
              </label>
              <input
                required
                type="email"
                className="form-control my-input"
                id="email"
                placeholder={
                  currentLanguage === "ar"
                    ? "أدخل عنوان بريدك الإلكتروني هنا"
                    : "Input your email address here"
                } style={{
                  textAlign: getLabelAlignment(),
                  direction: getDirection(),
                  display: getBlock() // Assuming you want to set display to "block"
                  // Assuming you want to set display to "block"
                }}
              />
            </div>
          </div>
          <div className="col-md-12">
            <label
              htmlFor="submitMessage"
              className="form-label landing-paragraph"
              style={{
                textAlign: getLabelAlignment(),
                direction: getDirection(),
                display: getBlock() // Assuming you want to set display to "block"
                // Assuming you want to set display to "block"
              }}            >
              {currentLanguage === "ar" ? "رسالة" : "Message"}
            </label>
            <textarea
              required
              type="text"
              className="form-control submit-Message my-input mb-5 text-normal"
              id="submitMessage"
              placeholder={
                currentLanguage === "ar"
                  ? "أدخل رسالتك هنا"
                  : "Input your message here"
              } style={{
                textAlign: getLabelAlignment(),
                direction: getDirection(),
                display: getBlock() // Assuming you want to set display to "block"
                // Assuming you want to set display to "block"
              }}
            />
          </div>
          <button className="btn text-white submit-btn rounded-3 mt-3 mx-auto">
            {currentLanguage === "ar" ? "إرسال الرسائل" : "Send Message"}
          </button>
          <div className="col-md-12 my-5">
            <div className="text-center">
              <h1 className="landing-paragraph mb-3">
                {currentLanguage === "ar"
                  ? "شركة مكامن المعرفة"
                  : "Knowledge Hub Ltd."}
              </h1>
              <p className="">
                {currentLanguage === "ar" ? "المملكة العربية السعودية" : "Saudi Arabia"}
              </p>
              <p className="">
                {currentLanguage === "ar"
                  ? "البريد الإلكتروني: info@knowhub.sa "
                  : "Email: info@knowhub.sa "}
              </p>
              <p className="">
                {currentLanguage === "ar"
                  ? "الهاتف : "
                  : "Phone : "}
                <span className="text-decoration-underline">
                  {currentLanguage === "ar" ? "٩٦٦٥٥٧٠٢٢٨٦١+" : "+966557022861"}
                </span>

                <span style={{ marginRight: '0.5em', marginLeft: '0.5em' }}> {currentLanguage === "ar" ? "أو" : "OR"} </span>
                <a
                  href="https://wa.me/+966557022861
                  "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className=""
                    src={whatsapp}
                    alt="whatsapp"
                    aria-hidden="true"
                  />{" "}
                  <span className="text-decoration-underline text-black">
                    {currentLanguage === "ar" ? " واتساب" : "WhatsApp"}
                  </span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
