import React, { useContext, useState } from "react";
import { ProjectsContext } from "../../Context/MediatestContext"; // Update the path
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import './style.css';
import { EffectCoverflow, Pagination } from 'swiper/modules';


export default function OurProject() {
  const { projectsData, fetchProjectsData } = useContext(ProjectsContext);
  const [currentLanguage, setCurrentLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem("language");
    return storedLanguage || "en"; // Default language is English
  });

  return (
    <>
      <div className="container">
        <h1 className="landing-paragraph text-center">
          {currentLanguage === "ar" ? "مشروعاتنا" : "Our Projects"}
        </h1>
        <div className="bottom-outline"></div>
        <div className="row" >
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            pagination={true}
            initialSlide={1} // Set the initial slide index to 1 (second slide)
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper"
          >
            {projectsData.map((project, index) => (
              <SwiperSlide key={index}>
                <img
                  src={project.image} // Assuming you have imageUrl in your project data
                  alt={project.title_en} // Assuming you have name in your project data
                  className="img-fluid rounded-2 mt-5 landing-bg"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

    </>
  );
}
