import React, { useContext, useEffect, useState } from "react";
import { CategoryContext } from "../../../Context/MediatestContext";
import { useNavigate, useParams } from "react-router-dom"; // Import useNavigate instead of useHistory
import { Link } from 'react-router-dom';
import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/cairo";
export default function SpItem() {
  const { CategoryData, fetchCategoryData } = useContext(CategoryContext);
  const id = useParams();
  const [currentLanguage, setCurrentLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem("language");
    return storedLanguage || "en"; // Default language is English
  });
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  // useEffect(() => {
  //   if (id.spitem) {
  //     fetchCategoryData(id.spitem);
  //   }
  // }, [id.spitem, fetchCategoryData]);
  useEffect(() => {
    if (id.spitem) {
      fetchCategoryData(id.spitem);
    }
  }, [id.spitem]);
  // Check if CategoryData exists and has the courses property before mapping
  if (!CategoryData || !CategoryData.courses) {
    return <div>Loading...</div>;
  }

  const handleClickLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "ar" : "en";
    setCurrentLanguage(newLanguage);
    localStorage.setItem("language", newLanguage); // Save language preference in local storage
  };

  const getDirection = () => {
    return currentLanguage === "en" ? "ltl" : "rtl";
  };
  const handleRedirect = (courseId) => {
    navigate(`/course/${courseId}`); // Use navigate instead of history.push
  };
  const handleRedirecttoconactus = () => {
    navigate(`/contactus`); // Use navigate instead of history.push
  };


  return (
    <>
      {/* <div
        className={`distanceCoveritem d-flex flex-column justify-content-center align-items-center p-5`}
      > */}
      <div
        className={`distanceCoveritem d-flex flex-column justify-content-center align-items-center`}
        style={{ direction: getDirection() }}
      >

        {/* <h6 className="text-white-50 text-center px-5">
          {currentLanguage === "en" ? "Home / " + CategoryData.title_en : "الرئيسية / " + CategoryData.title_ar}
        </h6> */}
        {/* <h4 className="text-white text-center px-3"
          style={{ fontSize: '30px', fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}  >{currentLanguage === "en" ? CategoryData.title_en : CategoryData.title_ar}</h4> */}
         <h1
              style={{
                fontFamily: currentLanguage === "ar" ? "cairo" : "Poppins",
              }}
              className="text-white text-center px-3"
            >
             {currentLanguage === "en" ? CategoryData.title_en : CategoryData.title_ar}
            </h1>

        
 
        
        {/* <div className="bottom-outline-2"></div> */}
        {/* <div className="my-5 landing-paragraph text-center p-5"  >
          <p style={{ fontSize: '22px', fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }} dangerouslySetInnerHTML={{ __html: currentLanguage === "en" ? CategoryData.Content_en.replace(/font-(size|family)/g, '') : CategoryData.Content_ar.replace(/font-(size|family)/g, '') }}></p>
        </div> */}
          <div className="container text-center my-5 p-5">
           
        <p style={{ fontSize: '22px', lineHeight: "2.5", color: "#042956", fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }} className="landing-paragraph text-center my-4"dangerouslySetInnerHTML={{ __html: currentLanguage === "en" ? CategoryData.Content_en.replace(/font-(size|family)/g, '') : CategoryData.Content_ar.replace(/font-(size|family)/g, '') }}></p>
        </div>

      </div>

      {/* <div className={`container text-center my-5 ${getDirection()}`}>
        <h4 style={{ fontSize: '30px', fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }} className="landing-paragraph px-5 my-2 text">{currentLanguage === "en" ? CategoryData.title_en : CategoryData.title_ar}</h4>
        <div className="bottom-outline-2"></div>
        <div className="my-5 landing-paragraph text-center "  >
          <p style={{ fontSize: '22px', fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }} dangerouslySetInnerHTML={{ __html: currentLanguage === "en" ? CategoryData.Content_en.replace(/font-(size|family)/g, '') : CategoryData.Content_ar.replace(/font-(size|family)/g, '') }}></p>
        </div>
      </div> */}

      <section className="section-bg">
        <div className="container mb-5">
          
          <h5 className="landing-paragraph pt-4 text-center" style={{ fontSize: '30px', fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}>
            {currentLanguage === "ar" ? "البرامج التدريبية الأكثر طلباً" : "In-Demand Trainings"}
          </h5>
          <div className="row py-4 ">
            {CategoryData.courses.map(course => (
              <div key={course.id} className="col-md-3 col-sm-6 col-lg-3  card bg-transparent border-0" style={{ padding: "4px 5px", minHeight: "155px" }}>
                <div className="captionParent" style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                  <img src={course.image} alt="" className="img-fluid card-img rounded-3" style={{ objectFit: 'cover', maxWidth: "100%", flex: "1" }} />
                  <div className="card-img-overlay">
                    <h5 style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }} className="card-text text-white h-100 d-flex justify-content-start align-items-end">
                      {currentLanguage === "en" ? course.title_en : course.title_ar}
                    </h5>
                    <div className="caption">
                      <div className="">


                        <p className="text-white ">
                          <p
                            style={{
                              direction: getDirection(),
                              fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins',
                              fontSize: currentLanguage === "ar" ? '12px' : '12px'
                            }}
                            dangerouslySetInnerHTML={{
                              __html: currentLanguage === "en" ? course.hover_en.replace(/font-(size|family)/g, '') : course.hover_ar.replace(/font-(size|family)/g, '')
                            }}
                          ></p>
                        </p>


                        {course.details && course.details.length > 0 ? (
                          <div className="button" style={{ position: "absolute", bottom: 0, left: 0, right: 0, display: "flex", justifyContent: "center" }}>
                            <button
                              className="submit-btn border-0 text-white mt-5 my-2 p-3 text-center h-15 d-flex justify-content-center align-items-center pt-3 rounded-2"
                              onClick={() => handleRedirect(course.id)} // Call handleRedirect on button click
                              style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }} >

                              {currentLanguage === "en" ? "View Details" : "رؤية التفاصيل"}
                              {course.details.id}
                            </button>

                          </div>
                        ) : (
                          // <Link to="/contactus" className="submit-btn border-0 text-white mt-5 my-2 p-3 text-center h-25 d-flex justify-content-center align-items-center pt-3 rounded-2">
                          //   {currentLanguage === "en" ? "Contact Us" : "تواصل معنا"}
                          // </Link>
                          <div className="button" style={{ position: "absolute", bottom: 0, left: 0, right: 0, display: "flex", justifyContent: "center" }}>

                            <button
                              className="submit-btn border-0 text-white mt-5 my-2 p-3 text-center h-25 d-flex justify-content-center align-items-center pt-3 rounded-2"
                              onClick={() => handleRedirecttoconactus()} // Call handleRedirect on button click
                              style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }} >
                              {currentLanguage === "en" ? "Contact Us" : "تواصل معنا"}
                            </button>
                          </div>

                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <p style={{ maxWidth: "818px", margin: "auto", textAlign: "center", fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}>
          {currentLanguage === "ar" ? (
            <>
              هل تشعر بالفضول حول دوراتنا؟ نحن هنا لتقديم معلومات مفصلة عن مجموعتنا الشاملة من الدورات واستكشاف خيارات التدريب التي قد لا تكون مدرجة بعد.
            </>
          ) : (
            <>
              Curious about our courses? We're here to provide detailed information on our comprehensive range of courses and explore training options that might not yet be listed.
            </>
          )}
        </p>

        <div className="button" style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="submit-btn border-0 text-white mt-5 my-2 p-3 text-center h-25 d-flex justify-content-center align-items-center pt-3 rounded-2"
            onClick={() => handleRedirecttoconactus()}
            style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}
          >
            {currentLanguage === "en" ? "Contact Us" : "تواصل معنا"}
          </button>
        </div>

      </section>

      {/* <div className={`container text-center my-5 ${getDirection()}`}>
        <p className="landing-paragraph">{currentLanguage === "en" ? "Curious about our courses? We're here to provide detailed information on our comprehensive range of courses and explore training options that might not yet be listed." : "فضولي حول دوراتنا؟ نحن هنا لتقديم معلومات مفصلة حول مجموعتنا الشاملة من الدورات واستكشاف خيارات التدريب التي قد لا تكون مدرجة بعد."}</p>
        <button className="submit-btn border-0 text-white">Contact Us</button>
      </div> */}
    </>
  );
}
