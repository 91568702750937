import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Layout from "./Components/Layout/Layout";
import Home from "./Components/Home/Home";
import Specialty from "./Components/Specialty/Specialty";
import DistanceTraining from "./Components/DistanceTraining/DistanceTraining";
import CompanyProfile from "./Components/CompanyProfile/CompanyProfile";
import Blog from "./Components/Blog/Blog";
import CourseContent from "./Components/TrainingPrograms/CourseContent/CourseContent";
import BlogItem from "./Components/Blog/BlogItem/BlogItem";
import SpItem from "./Components/Specialty/SpItem/SpItem.jsx";
import ContactForm from "./Components/ContactForm/ContactForm.jsx";

import ContactFormus from "./Components/ContactForm/ContactFormnav.jsx";

import EnglishPrograms from "./Components/EnglishPrograms/EnglishPrograms.jsx";

// import NewHome from "./Components/NewHome/NewHome";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function App() {
  let routers = createBrowserRouter([
    {
      path: "",
      element: <Layout />,
      children: [
        { index: true, element: <Home /> },
        { path: "specialty/:spitem", element: <Specialty /> },
        { path: "distance-training/:spitem", element: <DistanceTraining /> },
        { path: "englishprograms/:spitem", element: <EnglishPrograms /> },
        { path: "course/:coursecontent", element: <CourseContent /> },
        { path: "category/:spitem", element: <SpItem /> },
        { path: "company-profile", element: <CompanyProfile /> },
        { path: "blog", element: <Blog /> },
        { path: "blog/:blogitem", element: <BlogItem /> },
        { path: "contact", element: <ContactForm /> },
        { path: "contactus", element: <ContactFormus /> },

      ],
    },
  ]);
  return (
    <>
      <RouterProvider router={routers} />
    </>
  );
}

export default App;
