import React, { useContext, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import BlogItemStyle from "./BlogItem.module.css";
import CourseStyle from "../../TrainingPrograms/CourseContent/CourseContent.module.css";
import { PlogsContext, plogContext } from "../../../Context/MediatestContext";

export default function BlogItem() {
  const { plogData, fetchplogData } = useContext(plogContext);
  const id = useParams();
  const storedLanguage = localStorage.getItem("language") || "en";

  useEffect(() => {
    console.log("plogData:", plogData); // Debug log
    if (id) {
      fetchplogData(id.blogitem);
    }
  }, [id]); // Ensure all dependencies are included

  // Rest of your component code...

  const getDirection = () => {
    return storedLanguage === "ar" ? "rtl" : "ltr";
  };

  return (
    <>
      {plogData && (
        <div>
          <div className={`${CourseStyle.navCover} mb-5`}></div>
          <div className={`container`}>
            <div className="row my-5" style={{ fontFamily: storedLanguage === "ar" ? 'cairo' : 'Poppins' }}>
              <div className="col-md-12 position-relative">
                <img
                  src={plogData.image}
                  alt={storedLanguage === "en" ? plogData.title_en : plogData.title_ar}
                  className={`${BlogItemStyle.blogImg} position-relative`}
                  style={{ minWidth: '-webkit-fill-available' }}
                />
                <div
                  className={`${BlogItemStyle.blogContent} blog-content px-4 text-white`}

                  style={{ direction: getDirection(), fontFamily: storedLanguage === "ar" ? 'cairo' : 'Poppins' }}
                >
                  {/* <h3 className={`fw-bold ${BlogItemStyle.title}`}>
                    {storedLanguage === "en" ? plogData.title_en : plogData.title_ar}
                  </h3> */}
                  {/* <p className={`fw-bold ${BlogItemStyle.description}`}>
                    {storedLanguage === "en" ? plogData.sub_title_en : plogData.sub_title_ar}
                  </p> */}
                  {/* <span className={`fw-bold d-block ${BlogItemStyle.date}`}>
                    {plogData.created_at}
                  </span> */}
                  {/* <span className={`fw-bold d-block ${BlogItemStyle.location}`}>
                    {storedLanguage === "en" ? "Saudi Arabia" : "السعودية"}
                  </span> */}
                </div>
              </div>
              <p className={`${BlogItemStyle.blogOverview} mb-5`} style={{ fontFamily: storedLanguage === "ar" ? 'cairo' : 'Poppins', direction: getDirection() }}>
                <p style={{ fontFamily: storedLanguage === "ar" ? 'cairo' : 'Poppins' }} dangerouslySetInnerHTML={{
                  __html: storedLanguage === "en" && plogData && plogData.Content_en ?
                    plogData.Content_en.replace(/font-(size|family)="\S*"/g, '') :
                    storedLanguage === "ar" && plogData && plogData.Content_ar ?
                      plogData.Content_ar.replace(/font-(size|family)/g, '')  :
                      ""
                }}></p>
              </p>
            </div>
          </div>
          <div className="container">
            <div className="row my-5">
              <div className="col-md-12">
                <div className="blogSlider d-flex align-items-center justify-content-between">
                  {/* {plogData.id > 1 && ( )} */}
                  <div className="previousBlog text-center d-flex justify-content-center align-items-center">
                    <Link className="link-blog" to={`/blog/${plogData.id - 1}`}>
                      <i className="fa fa-circle-arrow-left fa-3x cursor-pointer mx-2" aria-hidden="true"></i>
                    </Link>
                    <span style={{ fontFamily: storedLanguage === "ar" ? 'cairo' : 'Poppins' }} className="fs-3">{storedLanguage == 'en' ? 'Previous' : 'السابق'}</span>
                  </div>

                  <div className="nextBlog text-center d-flex justify-content-center align-items-center">
                    {/* {plogData.id < plogData.length && (   )} */}
                    <Link className="link-blog" to={`/blog/${plogData.id + 1}`}>
                      <i className="fa fa-circle-arrow-right fa-3x cursor-pointer mx-2" aria-hidden="true"></i>
                    </Link>

                    <span style={{ fontFamily: storedLanguage === "ar" ? 'cairo' : 'Poppins' }} className="fs-3 me-2">{storedLanguage == 'en' ? 'Next' : 'التالى'}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
