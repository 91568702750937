import React, { useContext, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import SpecialtyStyle from "../Specialty/Speciality.module.css";
import { CourseContext } from "../../Context/MediatestContext";
import LoadingSpinner from "../../images/Spinner@1.25x-0.5s-200px-200px.svg";

export default function Specialty() {
  const { courseData, fetchCourseData } = useContext(CourseContext);
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const id = useParams();

  useEffect(() => {
    if (id.spitem) {
      fetchCourseData(id.spitem);
    }
  }, [id.spitem]);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setCurrentLanguage(storedLanguage);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // Set loading to false after 1 second
    }, 2000);

    return () => clearTimeout(timer); // Clear timer on component unmount
  }, []);

  const setLanguage = (language) => {
    setCurrentLanguage(language);
    localStorage.setItem("language", language);
  };

  const getDirection = () => {
    return currentLanguage === "en" ? "ltr" : "rtl";
  };

  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <>
      {isLoading ? (
        <div className="loading-spinner-container">
          <div className="loading-spinner">
            <img src={LoadingSpinner} alt="Loading" />
          </div>
        </div>
      ) : (
        <>
          <div
            className={`specialtyCover d-flex flex-column justify-content-center align-items-center  z`}
            style={{ direction: getDirection() }}
          >
            <h1
              style={{
                fontFamily: currentLanguage === "ar" ? "cairo" : "Poppins",
              }}
              className="text-white text-center px-3"
            >
              {currentLanguage === "en" ? "Speciality" : "التخصص"}
            </h1>
          </div>

          
          <section  style={{ direction: getDirection() }}>
            <div className="container my-5 ">
              <div className="row">
                {courseData ? (
                  courseData.map((item, index) => (
                    <div
                    key={index}
                    className={`col-md-4 col-lg-4 col-ms-12 mt-5 my-4 headBg`}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(false)}
                    onTouchStart={() => setHoveredIndex(index)}
                    onTouchEnd={() => setHoveredIndex(false)}
                  >
                      <Link to={`/category/${item.id}`}>
                        <div className="captionParent">
                          <img
                            src={item.image}
                            alt=""
                            className="img-fluid rounded-2"
                            style={{ maxHeight: "265px", minWidth: "450px" }}
                          />
                          <div
                            className="caption"
                            style={{
                              padding: "15px",
                              fontFamily:
                                currentLanguage === "ar" ? "cairo" : "Poppins",
                            }}
                          >
                            <div className="">
                              <p className="text-white lh-5">
                                 
                                <p
                                  style={{
                                    
                                    fontSize: "15px",
                                    fontFamily:
                                      currentLanguage === "ar"
                                        ? "cairo"
                                        : "Poppins"
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      currentLanguage === "en"
                                      
                                        ? item.hover_en.replace(/font-(size|family)/g, '')
                                        : item.hover_ar.replace(/font-(size|family)/g, ''),
                                  }}
                                ></p>
                              </p>
                            </div>
                          </div>
                        </div>

                        <h4
                          className={`my-2 p-3 text-center h-25 d-flex justify-content-center align-items-center pt-3 rounded-2 ${SpecialtyStyle.sectionTitle}`}
                        >
                          {hoveredIndex === index
                            ? currentLanguage === "en"
                              ? "Learn More"
                              : "تعرف المزيد"
                            : currentLanguage === "en"
                            ? item.title_en
                            : item.title_ar}
                        </h4>
                      </Link>
                    </div>
                  ))
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </section>
          <div>{/* Language buttons here */}</div>
        </>
      )}
    </>
  );
}
