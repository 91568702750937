import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/SVG/logo.svg";
import {
  SpecialtyContext,
  fetchSpecialtyData,
} from "../../Context/MediatestContext";

export default function Navbar() {
  const specialtyData = useContext(SpecialtyContext);
  const [currentLanguage, setCurrentLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem("language");
    return storedLanguage || "en";
  });

  useEffect(() => {
    fetchSpecialtyData().then((data) => {
      console.log(data);
    });
  }, []);

  const handleClickLanguage = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem("url", window.location.href);

      const newLanguage = currentLanguage === "en" ? "ar" : "en";
      setCurrentLanguage(newLanguage);
      localStorage.setItem("language", newLanguage);
      console.log(localStorage.getItem("url"));

      window.location.href = localStorage.getItem("url"); // Reload the current URL
    }
  };



  const getDirection = () => {
    return currentLanguage === "en" ? "ltr" : "rtl";
  };

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [isDistanceCoverExpanded, setIsDistanceCoverExpanded] = useState(false);

  const toggleDistanceCoverHeight = () => {
    setIsDistanceCoverExpanded(prevState => !prevState);
  };

  return (
    <>
      <div
        className={`distanceCoveritems d-flex flex-column justify-content-center align-items-center ${isDistanceCoverExpanded ? 'expanded' : ''}`}
      >
        {/* Content of distanceCoveritems */}
      </div>

      <nav className={`navbar bg-transparent navbar-expand-lg navbarCustomize ${getDirection()}`}>
        <div className="container">
          <div className="logo cursor-pointer">
            <Link to="">
              <img src={logo} alt="logo" className="img-fluid" />
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => { setIsNavbarOpen(prevState => !prevState); toggleDistanceCoverHeight(); }}
            aria-label="Toggle navigation"
          >
            <i class="fa-solid fa-bars" style={{ color: '#fff' }}></i>
          </button>
          <div className={`collapse navbar-collapse justify-content-end ${isNavbarOpen ? 'show' : ''}`} id="navbarSupportedContent">
            <ul className="navbar-nav list-unstyled pt-4 d-flex flex-column flex-md-row justify-content-between align-items-center text-decoration-none mb-4" style={{ direction: getDirection(), fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}>

              {specialtyData.map((item, index) => (
                <li key={index} >
                  {item.is_course === "0" && item.is_uni === "0" && (
                    <Link className="text-white" to="/" id={item.id} data-id={item.id}>
                      {currentLanguage === "en" ? item.Title_en : item.Title_ar}
                    </Link>
                  )}
                  {item.is_uni === "1" && (
                    <Link
                      to={`/distance-training/${item.id}`}
                      id={item.id}
                      data-id={item.id}
                      className="text-white"
                    >

                      {currentLanguage === "en" ? item.Title_en : item.Title_ar}
                    </Link>
                  )}
                  {item.is_course === "2" && (
                    <Link
                      className="text-white"
                      to={`/EnglishPrograms/${item.id}`}
                      id={item.id}
                      data-id={item.id}
                    >
                      {currentLanguage === "en" ? item.Title_en : item.Title_ar}
                    </Link>
                  )}

                  {item.is_course === "1" && (
                    <Link
                      key={item.id}
                      to={`/Specialty/${item.id}`}
                      id={item.id}
                      data-id={item.id}
                      className="text-white"
                    >
                      {currentLanguage === "en" ? item.Title_en : item.Title_ar}
                    </Link>
                  )}
                </li>
              ))}

              <li >
                <Link to="blog" className="text-white">

                  {currentLanguage === "en" ? "Blog" : "المدونة"}
                </Link>
              </li>
              <li >
                <Link to="company-profile" className="text-white">
                  {currentLanguage === "en" ? "Company Profile" : "ملف الشركة"}
                </Link>
              </li>
              <li className="dropdown-toggle" onClick={handleClickLanguage}>
                <Link className="text-white" to="">{currentLanguage === "en" ? "عربي" : "EN"}</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav >

    </>
  );
}
