import React, { useContext, useState, useEffect } from "react";
import { CourseContext } from "../../Context/MediatestContext";
import { Link } from "react-router-dom";
import AOS from "aos";
import "../../../node_modules/aos/dist/aos.css";
import landingOne from "../../images/Frame-1.svg";
import landingtwo from "../../images/Frame-2.svg";
import landingthree from "../../images/Frame.svg";
import landingfoure from "../../images/Protect Nature.svg";
import "@fontsource/cairo"; // Defaults to weight 400
import "@fontsource/poppins"; // Defaults to weight 400
import { useParams } from "react-router-dom";
import SpecialtyStyle from "../Specialty/Speciality.module.css";
import LoadingSpinner from "../../images/Spinner@1.25x-0.5s-200px-200px.svg";

export default function DistanceTraining() {
  const { courseData, fetchCourseData } = useContext(CourseContext);
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const id = useParams();

  useEffect(() => {
    if (id.spitem) {
      fetchCourseData(id.spitem);
    }
  }, [id.spitem]);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setCurrentLanguage(storedLanguage);
    }
  }, []);

  const setLanguage = (language) => {
    setCurrentLanguage(language);
    localStorage.setItem("language", language);
  };

  const getDirection = () => {
    return currentLanguage === "en" ? "ltr" : "rtl";
  };

  AOS.init({
    duration: 1500,
    once: false,
    disable: false,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // Set loading to false after 1 second
    }, 2000);

    return () => clearTimeout(timer); // Clear timer on component unmount
  }, []);

  return (
    <>
      {isLoading ? ( // Show loading screen if isLoading is true
        <div className="loading-spinner-container">
          <div className="loading-spinner">
            <img src={LoadingSpinner} alt="Loading" />
          </div>
        </div>) : (
        <>
          {/* <div className={`distanceCover d-flex flex-column justify-content-center align-items-center p-5`}> */}
          {/* <h6 className="text-white-50 text-center px-5">{currentLanguage === "en" ? "Home / Distance Training" : "الرئيسية / التدريب عن بعد"}</h6> */}
          {/* </div> */}
          {/* <div className={` distanceCoveritem d-flex flex-column justify-content-center align-items-center p-5 min-vh2`} dir={getDirection()}>

        <h6 className="text-white text-center px-5">{currentLanguage === "en" ? "Home / Distance Training" : "الرئيسية / التدريب عن بعد"}</h6>

      </div> */}


          {/* <div
            className={`distanceCoveritem d-flex flex-column justify-content-center align-items-center p-5`}
          > */}

          <div
            className={`distanceCover d-flex flex-column justify-content-center align-items-center  `}
          >
            {/* <h6 style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }} className="text-white-50 text-center px-5">
                {currentLanguage === "en" ? "Home / Distance Training" : "الرئيسية / التدريب عن بعد"}
              </h6> */}
            <h1
              style={{
                fontFamily: currentLanguage === "ar" ? "cairo" : "Poppins",
              }}
              className="text-white text-center px-3"
            >
                    {currentLanguage === "en" ? "Distance Training" : "التدريب عن بعد"}
            </h1>


          </div>

          <div className={`container my-5 p-5`} dir={getDirection()}>
            <div className="row">
              <div clssName="mb-5">
                {/* <h3 style={{ fontSize: '30px', fontfamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }} className="landing-paragraph text-center ">
                    {currentLanguage === "en" ? "Distance Training" : "التدريب عن بعد"}
                  </h3> */}
                {/* <div className="bottom-outline"></div> */}
                <p style={{ fontSize: '22px', lineHeight: "2.5", color: "#042956", fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }} className="landing-paragraph text-center my-4">
                  {currentLanguage === "en" ?
                    "Distance learning is a method of education that allows students to learn from a distance, without having to attend classes in person. This can be done through a variety of methods, including online courses, video conferencing, and correspondence courses. Distance learning is a popular option for students who are unable to attend traditional classes due to work, family commitments, or other reasons. It is also a flexible option that allows students to learn at their own pace."
                    :
                    "التعلم عن بعد هو أسلوب تعليم يتيح للطلاب التعلم عن بعد، دون الحاجة إلى حضور الدروس شخصيا. يمكن القيام بذلك من خلال مجموعة متنوعة من الطرق، بما في ذلك الدورات عبر الإنترنت والفيديوconferencing، والدورات البريدية. التعلم عن بعد هو خيار شائع بين الطلاب الذين لا يستطيعون حضور الدروس التقليدية بسبب العمل أو التزامات الأسرة أو أسباب أخرى. كما أنه خيار مرن يسمح للطلاب بالتعلم بوتيرتهم الخاصة."}
                </p>
              </div>
              <div className="text-center my-5">
                <h3 style={{ fontSize: '30px', fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }} className="landing-paragraph text-center">
                  {currentLanguage === "ar" ? "بعض شركائنا في التعلم عن بعد" : "Some of Our Distance-Learning Partners"}
                </h3>

                <div className="bottom-outline w-50"></div>
              </div>
              {courseData && courseData.map((item, index) => (
                <React.Fragment key={index}>
                  {index % 2 === 0 ? (
                    <>
                      <div className="col-md-6 landing-paragraph mt-5" data-aos="fade-down">
                        <h3 style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins', fontSize: "22px", color: "#042956" }} className="fw-bold">{index + 1}. {currentLanguage === "en" ? item.title_en : item.title_ar}</h3>
                        <p style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins', fontSize: "20px", color: "#042956" }} >{currentLanguage === "en" ? item.sub_title_en : item.sub_title_ar}</p>
                        <div style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins', fontSize: "18px", color: "#042956" }} className="landing-paragraph fw-lighter">
                          <p style={{ fontSize: "18px", fontFamily: currentLanguage === "ar" ? 'Cairo' : 'Poppins' }} dangerouslySetInnerHTML={{
                            __html: currentLanguage === "en" ?
                              item.Content_en.replace(/font-(size|family)/g, '') :
                              item.Content_ar.replace(/font-(size|family)/g, '')
                          }}></p>
                        </div>
                      </div>
                      <div className="col-md-6 gap-2 landing-bg py-4 my-5 d-flex justify-content-center align-items-center" data-aos="fade-up">
                        <img src={item.image} alt="" className="img-fluid p-5 my-3" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-md-6 gap-2 landing-bg py-4 my-5 d-flex justify-content-center align-items-center" data-aos="fade-up">
                        <img src={item.image} alt="" className="img-fluid p-5 my-3" />
                      </div>
                      <div className="col-md-6 landing-paragraph mt-5" data-aos="fade-down">
                        <h3 style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins', fontSize: "22px", color: "#042956" }} className="fw-bold">{index + 1}. {currentLanguage === "en" ? item.title_en : item.title_ar}</h3>
                        <p style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins', fontSize: "20px", color: "#042956" }} >{currentLanguage === "en" ? item.sub_title_en : item.sub_title_ar}</p>
                        <div style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins', fontSize: "18px", color: "#042956" }} className="landing-paragraph fw-lighter">
                          <p style={{ fontSize: "18px", fontFamily: currentLanguage === "ar" ? 'Cairo' : 'Poppins' }} dangerouslySetInnerHTML={{
                            __html: currentLanguage === "en" ?
                              item.Content_en.replace(/font-(size|family)/g, '') :
                              item.Content_ar.replace(/font-(size|family)/g, '')
                          }}></p>
                        </div>
                      </div>
                    </>
                  )}
                </React.Fragment>
              ))}
              {/* Additional content here */}


            </div>
            <p></p>
            {/* <div className={`container my-5 staticboxs`} dir={getDirection()}> */}
            <div className="text-center">
              <h3 className="fw-bold" style={{ color: "var(--paragraph-color, #042956)" }}>
                {currentLanguage === "ar"
                  ? "فوائد إضافية للتعلم عن بعد"
                  : "Additional Benefits of Distance Learning:"}
              </h3>

              <p style={{ color: "#757575" }}>
                {currentLanguage === "ar"
                  ? "يمكن لمركز المعرفة مساعدتك في العثور على الدورة المناسبة لاحتياجاتك وترتيب التدريب مع جامعة لديها سمعة طيبة في التعلم عن بعد"
                  : "Knowledge Hub can help you find the right course for your needs and arrange for training with a university that has a good reputation for distance learning"}
              </p>
            </div>
            <div className="benefits" >
              <div className="row" style={
                { fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }
              }>
                <div className="col-md-3 col-6  col-sm-6 ">
                  <div className="card border-0" style={{ height: '350px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <img className="card-img-top mt-5" src={landingtwo} style={{ width: '57.99px', height: '64.96px' }} alt="Card image cap" />
                    </div>
                    <div class="card-body mb-10" style={{ textAlign: 'center' }}>
                      <h5 class="card-title" style={{ color: "var(--paragraph-color, #042956)", fontSize: "large" }}>
                        {currentLanguage === "ar" ? "التوفر" : "Accessibility"}
                      </h5>
                      <p class="card-text" style={{ color: "var(--paragraph-color, #042956)" }}>
                        {currentLanguage === "ar" ? "يمكن أن يجعل التعلم عن بُعد التعليم أكثر إمكانية للأشخاص الذين لن يتمكنوا بخلاف ذلك من حضور الدروس شخصيًا." : "Distance learning can make education more accessible to people who would not otherwise be able to attend classes in person."}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="card border-0" style={{ height: '350px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <img className="card-img-top mt-5" src={landingOne} style={{ width: '57.99px', height: '64.96px' }} alt="Card image cap" />
                    </div>
                    <div class="card-body" style={{ textAlign: 'center' }}>
                      <h5 class="card-title" style={{ color: "var(--paragraph-color, #042956)", fontSize: "large", fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}>
                        {currentLanguage === "ar" ? "التكلفة" : "Affordability"}
                      </h5>
                      <p class="card-text" style={{ color: "var(--paragraph-color, #042956)", fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}>
                        {currentLanguage === "ar" ? "غالبًا ما يكون التعلم عن بُعد أكثر تكلفة من الدورات التقليدية التي تُقدم في الفصول الدراسية." : "Distance learning is often more affordable than traditional classroom-based courses."}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="card border-0" style={{ height: '350px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <img className="card-img-top mt-5" src={landingthree} style={{ width: '57.99px', height: '64.96px' }} alt="Card image cap" />
                    </div>
                    <div class="card-body mb-5" style={{ textAlign: 'center' }}>
                      <h5 class="card-title" style={{ color: "var(--paragraph-color, #042956)", fontSize: "large" }}>
                        {currentLanguage === "ar" ? "مرونة" : "Flexibility"}
                      </h5>
                      <p class="card-text" style={{ color: "var(--paragraph-color, #042956)" }}>
                        {currentLanguage === "ar" ? "يمكنك اختيار الدورات التي تناسب جدولك الزمني واهتماماتك." : "You can choose the courses that fit your schedule and interests."}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="card border-0" style={{ height: '350px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <img className="card-img-top mt-5" src={landingfoure} style={{ width: '57.99px', height: '64.96px' }} alt="Card image cap" />
                    </div>
                    <div class="card-body mb-5" style={{ textAlign: 'center' }}>
                      <h5 class="card-title" style={{ color: "var(--paragraph-color, #042956)", fontSize: "large" }}>
                        {currentLanguage === "ar" ? "الراحة" : "Convenience"}
                      </h5>
                      <p class="card-text" style={{ color: "var(--paragraph-color, #042956)" }}>
                        {currentLanguage === "ar" ? "يسمح التعلم عن بُعد لك بالتعلم بوتيرتك الخاصة ومن أي مكان في العالم." : "Distance learning allows you to learn at your own pace and from anywhere in the world."}
                      </p>
                    </div>

                  </div>
                </div>
              </div>

            </div>
            <p></p>
            <p style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins', maxWidth: "855px", margin: "auto", textAlign: "center", color: "var(--paragraph-color, #042956)", lineHeight: "39.5px" }}>
              {currentLanguage === "ar" ? (
                <>
                  إذا كنت مهتمًا بمعرفة المزيد عن التعلم عن بُعد،{" "}
                  <Link to="/contactus" style={{ color: "#E6882B", textDecoration: "none" }}>يرجى الاتصال بمركز المعرفة.</Link>
                  سنكون سعداء بمساعدتك في العثور على الدورة المناسبة لاحتياجاتك.
                </>
              ) : (
                <>
                  If you are interested in learning more about distance learning,{" "}
                  <Link to="/contactus" style={{ color: "#E6882B", textDecoration: "none" }}>please contact Knowledge Hub.</Link>
                  {" "} We would be happy to help you find the right course for your needs.
                </>
              )}
            </p>


          </div>
          {/* </div> */}

          {/* Additional content here */}


        </>
      )}
    </>
  );
}
