import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SpecialtyContext, fetchSpecialtyData } from "../../Context/MediatestContext";
import FooterStyle from "./Footer.module.css";
import logo from "../../images/SVG/FooterLogo.svg";

export default function Footer() {
  const specialtyData = useContext(SpecialtyContext); // Consume SpecialtyContext
  const [currentLanguage, setCurrentLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem("language");
    return storedLanguage || "en"; // Default language is English
  });

  useEffect(() => {
    fetchSpecialtyData().then(data => {
      console.log(data);
    });
  }, []);

  const handleClickLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "ar" : "en";
    setCurrentLanguage(newLanguage);
    localStorage.setItem("language", newLanguage); // Save language preference in local storage
  };

  const getDirection = () => {
    return currentLanguage === "en" ? "rtl" : "ltr";
  };

  return (
    <>
      <div className={`${FooterStyle.FooterStyle}`}>
        <div>
          <img src={logo} alt="" className={`img-fluid pt-5 mb-4`} />

          <ul className="list-unstyled w-100 m-0 d-flex flex-column flex-md-row justify-content-between align-items-center text-decoration-none text-white mb-4">
            {specialtyData.map((item, index) => (
              <li key={index} className="px-3 footer text-white"> {/* Added text-white class */}
                {item.is_course === '0' && item.is_uni === '0' && (
                  <Link style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }} to="/" id={item.id} data-id={item.id} className="text-white">
                    {currentLanguage === "en" ? item.Title_en : item.Title_ar}
                  </Link>
                )}
                {item.is_uni === '1' && (
                  <Link style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }} className="text-white" to={`/distance-training/${item.id}`} id={item.id} data-id={item.id}>{currentLanguage === "en" ? item.Title_en : item.Title_ar}</Link>
                )}
                {item.is_course === '2' && (
                  <Link style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }} className="text-white" to={`/EnglishPrograms/${item.id}`} id={item.id} data-id={item.id}>{currentLanguage === "en" ? item.Title_en : item.Title_ar}</Link>
                )}

                {item.is_course === '1' && (
                  <Link style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }} className="text-white" key={item.id} to={`/Specialty/${item.id}`} id={item.id} data-id={item.id}>{currentLanguage === "en" ? item.Title_en : item.Title_ar}</Link>
                )}
              </li>
            ))}

            <li className="px-3">
              <Link style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }} className="text-white" to="blog">{currentLanguage === "en" ? "Blog" : "المدونة"}</Link>
            </li>
            <li className="px-3">
              <Link style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }} className="text-white" to="company-profile">{currentLanguage === "en" ? "Company Profile" : "ملف الشركة"}</Link>
            </li>
            <li className="px-3 dropdown-toggle" onClick={handleClickLanguage}>
              <Link style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }} className="text-white" to="">{currentLanguage === "en" ? "EN" : "عربي"}</Link>
            </li>
          </ul>

        </div>


        <div className={`mb-5 ${FooterStyle.line}`}></div>
        <p style={{ fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }} className={`${FooterStyle.copyright} text-center pb-5`}>
          {currentLanguage === "ar" ? " © 2024.  جميع  الحقوق محفوظة" : "  © 2024. All Rights Reserved."}
        </p>

      </div>

    </>
  );
}
