import React, { useContext, useState, useEffect } from "react";
import { CourseContext } from "../../Context/MediatestContext";
import ContactForm from "../ContactForm/ContactForm";
import AboutUs from "../AboutUs/AboutUs";
import Methodology from "../Methodology/Methodology";
import Landing from "../Landing/Landing";
import OurProject from "../OurProject/OurProject";
import HomeStyle from "../Home/Home.module.css";
import landingThree from "../../images/landingThree.jpeg";
import landingFour from "../../images/landingFour.jpeg";
import AOS from "aos";
import "../../../node_modules/aos/dist/aos.css";
import MediaItem from "../MediaItem/MediaItem";
import LoadingSpinner from "../../images/Spinner@1.25x-0.5s-200px-200px.svg";

export default function Home() {
  const [loading, setLoading] = useState(true); // Loading state
  const { courseData, fetchCourseData } = useContext(CourseContext);
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const id = 1;
  const [fetchCompleted, setFetchCompleted] = useState(false);

  AOS.init({
    duration: 1500,
    once: false,
    disable: false,
  });


  useEffect(() => {
    if (id) {
      fetchCourseData(id);
    }
  }, [id]);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setCurrentLanguage(storedLanguage);
    }
  }, []);

  const setLanguage = (language) => {
    setCurrentLanguage(language);
    localStorage.setItem("language", language);
  };

  const getDirection = () => {
    return currentLanguage === "ar" ? "rtl" : "ltr";
  };


  useEffect(() => {
    setTimeout(() => {
      setLoading(false); // Set loading to false after 1 second
    }, 2000);
  }, []);



  const fifthCourseData = courseData && courseData.length > 5 ? courseData[5] : null;
  const sixCourseData = courseData && courseData.length > 6 ? courseData[6] : null;

  if (loading) {

    return (
      <div className="loading-spinner-container">
        <div className="loading-spinner">
          <img src={LoadingSpinner} alt="Loading" />
        </div>
      </div>
    );

  }

  return (
    <>
      <section className="text-start">
        <div className="containersss">
          <Landing courseData={courseData} fetchCourseData={fetchCourseData} />
        </div>
        <div className="container ">
          <div className="container ">
            <AboutUs courseData={courseData} fetchCourseData={fetchCourseData} />
          </div>
        </div>
        <Methodology courseData={courseData} fetchCourseData={fetchCourseData} />


        <div className="container d-flex justify-content-center my-5" data-aos="fade-down">
          <div className="row my-5">
            {currentLanguage === "ar" ? (
              <>

                <div className="col-md-6 my-5 ">
                  <h1 className={`${HomeStyle.sectionTitle}`} style={{ direction: getDirection(), textAlign: 'justify',fontFamily:"cairo" }}>
                    {fifthCourseData ?
                      <h1 dangerouslySetInnerHTML={{ __html: fifthCourseData.title_ar.replace(/font-(size|family)/g, '') }}></h1>
                      : "Loading..."
                    }
                  </h1>



                  <p className={`${HomeStyle.landingParagraph} lh-lg`} style={{ direction: getDirection(), textAlign: 'justify',fontFamily:"cairo" }}>
                    {fifthCourseData ?
                      <p dangerouslySetInnerHTML={{ __html: fifthCourseData.Content_ar.replace(/font-(size|family)/g, '')  }}></p>
                      : "Loading..."
                    }
                  </p>

                </div>
                <div className="col-md-6 my-5">
                  {fifthCourseData ? (
                    <img src={fifthCourseData.image} alt="" className="img-fluid rounded-2" />
                  ) : (
                    <img src={landingThree} alt="" className="img-fluid rounded-2 " />
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="col-md-6 my-5">
                  {fifthCourseData ? (
                    <img src={fifthCourseData.image} alt="" className="img-fluid rounded-2" />
                  ) : (
                    <img src={landingThree} alt="" className="img-fluid rounded-2 " />
                  )}
                </div>
                <div className="col-md-6 my-5">
                  <h1 className={`${HomeStyle.sectionTitle}`} style={{ direction: getDirection(), textAlign: 'start',fontFamily:"Poppins" }}>
                    {fifthCourseData ?
                      <h1 dangerouslySetInnerHTML={{ __html: fifthCourseData.title_en.replace(/font-(size|family)/g, '') }}></h1>
                      : "Loading..."
                    }
                  </h1>

                  <p className={`${HomeStyle.landingParagraph} lh-lg`} style={{ direction: getDirection(), textAlign: 'start' }}>
                    {fifthCourseData ?
                      <p dangerouslySetInnerHTML={{ __html: fifthCourseData.Content_en }}></p>
                      : "Loading..."
                    }
                  </p>

                </div>
                {/* <div className="col-md-6 my-5 ">
                  {fifthCourseData ? (
                    <img src={fifthCourseData.image} alt="" className="img-fluid rounded-2" />
                  ) : (
                    <img src={landingThree} alt="" className="img-fluid rounded-2 " />
                  )}
                </div> */}
              </>
            )}
          </div>
        </div>

        <div className={`my-5 ${HomeStyle.landingBg}`}>
          <div className="container mb-5">
            <div className="row mt-5 pb-4 d-flex justify-content-center align-items-center">
              {currentLanguage === "ar" ? (
                <>

                  <div className="col-md-5 my-5" data-aos="fade-down">
                    {sixCourseData ? (
                      <img src={sixCourseData.image} alt="" className="img-fluid rounded-2" />
                    ) : (
                      <img src={landingFour} alt="" className="img-fluid rounded-2 " />
                    )}
                  </div>

                  <div className="col-md-7 mt-5 lh-lg" data-aos="fade-down">
                    <h1 className="section-title" style={{ direction: getDirection(), textAlign: 'justify'  ,fontFamily:"cairo"}}>
                      {sixCourseData ? ( // Check if sixCourseData exists
                        sixCourseData.title_ar.replace(/font-(size|family)/g, '')
                      ) : (
                        "Loading..."
                      )}
                    </h1>
                    {sixCourseData && ( // Check if sixCourseData exists
                      <p style={{ direction: getDirection(), textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: sixCourseData.sub_title_ar }} className="text-white my-4"></p>
                    )}
                    <div className={`${HomeStyle.valSec}`} data-aos="fade-down">
                      {sixCourseData && ( // Check if sixCourseData exists
                        <p style={{ direction: getDirection(), textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: sixCourseData.Content_ar.replace(/font-family/g, '') }} className="text-white px-4"></p>
                      )}
                    </div>
                  </div>

                  {/* <div className="col-md-5 my-5" data-aos="fade-down">
                    {sixCourseData ? (
                      <img src={sixCourseData.image} alt="" className="img-fluid rounded-2" />
                    ) : (
                      <img src={landingFour} alt="" className="img-fluid rounded-2 " />
                    )}
                  </div> */}
                </>
              ) : (
                <>


                  <div className="col-md-7 mt-5 lh-lg" data-aos="fade-down">
                    <h1 className="section-title" style={{ direction: getDirection(), textAlign: 'start' }}>
                      {sixCourseData ? ( // Check if sixCourseData exists
                        sixCourseData.title_en
                      ) : (
                        "Loading..."
                      )}
                    </h1>
                    {sixCourseData && ( // Check if sixCourseData exists
                      <p style={{ direction: getDirection(), textAlign: 'start' }} dangerouslySetInnerHTML={{ __html: sixCourseData.sub_title_en }} className="text-white my-4"></p>
                    )}
                    <div data-aos="fade-down">
                      {sixCourseData && ( // Check if sixCourseData exists
                        <p style={{ direction: getDirection(), textAlign: 'start' }} dangerouslySetInnerHTML={{ __html: sixCourseData.Content_en }} className="text-white px-4"></p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-5 my-5" data-aos="fade-down">
                    {sixCourseData ? (
                      <img src={sixCourseData.image} alt="" className="img-fluid rounded-2" />
                    ) : (
                      <img src={landingFour} alt="" className="img-fluid rounded-2 " />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <OurProject className="mb-5" />
        <ContactForm />
      </section>
    </>
  );
}
