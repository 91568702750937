import landingTwo from "../../images/SVG/vision.svg";
import React, { useState, useEffect } from "react";

export default function Methodology({ courseData }) {
  const [currentLanguage, setCurrentLanguage] = useState("en"); // Default language is English

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setCurrentLanguage(storedLanguage);
    }
  }, []);

  const setLanguage = (language) => {
    setCurrentLanguage(language);
    localStorage.setItem("language", language);
  };

  const getDirection = () => {
    return currentLanguage === "ar" ? "rtl" : "ltr";
  };

  // Get the courseData of index 1
  const thirdCourseData = courseData && courseData.length > 3 ? courseData[3] : null;
  const block = courseData && courseData.length > 4 ? courseData[4] : null;
  const blocksArray = block && block.blocks ? block.blocks : [];

  return (
    <>
      <div className="my-5 text-center section-bg p-5" data-aos="fade-down">
        <h1 className="section-title my-5" style={{ direction: getDirection(), textAlign: 'center', fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}>
          {thirdCourseData ?
            <h1 dangerouslySetInnerHTML={{ __html: currentLanguage === "en" ? thirdCourseData.title_en : thirdCourseData.title_ar }}></h1>
            : "Loading..." // or some placeholder text
          }
        </h1>

        <p className="container landing-paragraph lh-lg mb-5" style={{ direction: getDirection(), textAlign: currentLanguage === "ar" ? 'justify' : 'start', fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}>
          {thirdCourseData ?
            <p dangerouslySetInnerHTML={{ __html: currentLanguage === "en" ? thirdCourseData.Content_en : thirdCourseData.Content_ar.replace(/font-family/g, '') }}></p>
            : "Loading..." // or some placeholder text
          }
        </p>
      </div>
      <div className="mb-5 fs-4" data-aos="fade-down">
        <h1 className="section-title my-5 text-center lh-lg" data-aos="fade-down" style={{ direction: getDirection(), textAlign: currentLanguage === "ar" ? 'justify' : 'start', fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}>
          {block ?
            (currentLanguage === "ar" ? block.title_ar : block.title_en)
            : "Loading..." // or some placeholder text
          }
        </h1>
        <p className="container text-center text-muted lh-ls" data-aos="fade-down" style={{ direction: getDirection(), textAlign: currentLanguage === "ar" ? 'justify' : 'start', maxWidth: '1150px', fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}>
          {block && block.blocks ?
            <p dangerouslySetInnerHTML={{ __html: currentLanguage === "ar" ? block.Content_ar.replace(/font-family/g, '') : block.Content_en }}></p>
            : "Loading..." // or some placeholder text
          }
        </p>
      </div>
      <h1 className="section-title my-5 text-center lh-lg" data-aos="fade-down" style={{ direction: getDirection(), textAlign: currentLanguage === "ar" ? 'justify' : 'start', fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}>
        {block && block.blocks ?
          (currentLanguage === "ar" ? block.blocks.title_ar : block.blocks.title_en)
          : "Loading..." // or some placeholder text
        }
      </h1>
      <p className="container text-center text-muted lh-lg" data-aos="fade-down" style={{ direction: getDirection(), textAlign: currentLanguage === "ar" ? 'justify' : 'start', fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}>
        {block && block.blocks ?
          (currentLanguage === "ar" ? block.blocks.sub_title_ar : block.blocks.sub_title_en)
          : "Loading..." // or some placeholder text
        }
      </p>
      <div className="container blocks" data-aos="fade-down" dir={getDirection()}>
        <div className="container row gx-lg-5 gy-4 my-5">
          {blocksArray.map((blockItem, index) => (
            <div className="col-lg-4 col-12 " key={index} data-aos="fade-down-right">
              <div className="section-bg  rounded-2 text-center p-4 h-100">
                <i className={`fa-solid fa-2x fa-${index + 1} mb-4 py-2 px-3 rounded-2 iconNumber text-white`}></i>
                <h5 className="section-title mb-3 px-2 ">{currentLanguage === "ar" ? blockItem.title_ar : blockItem.title_en}</h5>
                <p className="landing-paragraph" style={{ direction: getDirection(), textAlign: currentLanguage === "ar" ? 'justify' : 'start', fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}>
                  {currentLanguage === "ar" ?
                    <p dangerouslySetInnerHTML={{ __html: blockItem.Content_ar.replace(/font-family/g, '') }}></p>
                    :
                    <p dangerouslySetInnerHTML={{ __html: blockItem.Content_en }}></p>
                  }
                </p>
              </div>
            </div>
          ))}
        </div>
        {block && block.blocks && block.blocks.note_ar && block.blocks.note_en && (
          <p className="landing-paragraph text-center my-2" data-aos="fade-down" style={{ direction: getDirection(), textAlign: currentLanguage === "ar" ? 'justify' : 'start', fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}>
            <span dangerouslySetInnerHTML={{ __html: currentLanguage === "ar" ? block.blocks.note_ar : block.blocks.title_en }}></span>
          </p>
        )}


      </div>
      {/* <p className="landing-paragraph text-center my-2 " data-aos="fade-down" style={{ direction: getDirection(), textAlign: currentLanguage === "ar" ? 'justify' : 'start', margin: 'auto', 'maxWidth': '1150px', fontFamily: currentLanguage === "ar" ? 'cairo' : 'Poppins' }}>
        {block && block.blocks ?
          <p dangerouslySetInnerHTML={{ __html: currentLanguage === "ar" ? block.sub_title_ar : block.sub_title_en }}></p>
          : "Loading..." // or some placeholder text
        }
      </p> */}
    </>
  );
}
